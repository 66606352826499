export default {
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Refunds Form',
    footer: {
      title: 'Need to Start Your Request',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'Certificate Cancellation',
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Continue',
    noOrders: 'No refund protection found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      row2: 'Need to cancel the certificate you purchased? To receive the full refund of your shipping protection purchase, you must cancel your shipping protection within the cancellation window specified in our Terms and Conditions. Below you will find all of your previous orders. Please select an order below to view the certificates that you wish to cancel.',
    },
  },
  reviewPage: {
    header: {
      description:
        'Please review the information below. If you are sure you wish to cancel, click “Confirm Cancellation”.',
    },
    sign: {
      button: 'Confirm Certificate Cancellation',
      cancel: 'Do Not Cancel Certificate',
      note: 'Please note: Once you cancel your certificate, all your information will be immediately and permanently deleted. Be sure to export your data before cancelling your certificate.',
      claims: {
        text: "By clicking 'Submit Refund' you certify that all information on this refund request is accurate and truthful under  penalty of perjury.  FanShield, RegShield, and TourShield are marketing product names for the ticket event programme Refund Protection. Terms, Conditions,",
        footer:
          ' and Coverage may vary. See Application and Refund Protection for full details. You can view Protecht, ',
        floridaText: '',
        link: {
          text: {
            row2: 'updates concerning your Refund Protection.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Premium to be Refunded: ',
      title: 'Description',
      covered: 'Protected Amount',
    },
  },
  addressForm: {
    labels: {
      address: 'Address',
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      description: {
        row2: 'Confirmation',
      },
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted:
          'Thank You. Your Cancellation Request Has Been Submitted.',
      },
    },
    description:
      'Please retain your confirmation number above for future reference. Please ',
  },
};
