// react
import React from 'react';

// packages
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// layout
import V1Layout from 'layouts/V1';

// components
import HeaderDescription from 'components/HeaderDescription';

const PaymentFailure = function () {
  return (
    <V1Layout forceShowLayoutItems shouldShowLogo={false}>
      <Stack alignItems="center">
        <HeaderDescription title="Payment Failed">
          <Typography className="text-center" my={3}>
            We are unable to accept payment at this time, please try again
            later.
          </Typography>
        </HeaderDescription>
      </Stack>
    </V1Layout>
  );
};

export default PaymentFailure;
