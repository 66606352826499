// react
import { useMemo } from 'react';

// packages
import valid from 'card-validator';
import { DateTime } from 'luxon';
import * as yup from 'yup';

// redux
import { selectSelectedItems } from 'store/modules/orders/selectors';

// components
import { FormComponent, FormComponentEnum } from 'components/FormBuilder/types';

// hooks
import {
  resolveObjectFromConfigToReduxState,
  resolvePropFromConfigToReduxState,
  useAppSelector,
} from 'store/hooks';

// utils
import { formatBrowserCompatibleDate } from 'utils/date';
import { isObject } from 'utils/object';
import { replaceCamelCase } from 'utils/string';

// types
import type {
  ComponentStructure,
  DatePanelConfigProps,
  DecisionPanelConfigProps,
  FormFieldConfigProps,
} from 'types';
import type { Peril } from 'store/modules/perils/types';

type ValidatorStrings = {
  [key in FormComponent]: (name: string, required: boolean) => unknown;
};

const address = yup
  .string()
  .matches(/(?=^.{0,30}$)^([0-9a-zA-Z\s\-.,#()]|(\d\/\d)){0,30}$/, {
    message: 'Address does not match expected format.',
  });
const city = yup.string().matches(/^([0-9a-zA-Z\s.\-,]){1,20}$/, {
  message: 'City does not match expected format.',
});
const country = yup.string();
const customerName = yup
  .string()
  .matches(/^(?![.\-,]{1,25})([0-9a-zA-Z\s.\-,']){1,25}$/, {
    message: 'Name does not match expected format.',
  });
const postalCode = yup.string().matches(/^([0-9a-zA-Z\s-]){1,10}$/, {
  message: 'Postal code does not match expected format.',
});
const state = yup.string().matches(/^([a-zA-Z]){2}$/, {
  message: 'State does not match expected format.',
});

export const validatorStrings: ValidatorStrings = {
  InputPanel: (name, required) => {
    const schema = yup.string();
    return required
      ? schema.required(`The ${replaceCamelCase(name)} field is required.`)
      : schema;
  },
  TextAreaPanel: (name, required) => {
    const schema = yup.string();
    return required
      ? schema.required(`The ${replaceCamelCase(name)} field is required.`)
      : schema;
  },
  DatePanel: (name, required) => {
    let baseDateSchema = yup
      .string()
      .nullable()
      .test('lt', 'The year field must be 2000 or more.', (value) => {
        if (!value) return true;

        return (
          new Date(formatBrowserCompatibleDate(value)) >
          new Date(formatBrowserCompatibleDate('2000-01-01'))
        );
      })
      .typeError(`Invalid ${replaceCamelCase(name)}`);

    const validateMaxDate = (
      value: string | null | undefined,
      {
        days,
        hours,
        minutes,
        seconds,
        mseconds,
      }: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        mseconds: number;
      }
    ) => {
      if (!value) return true;

      const maxDate = new Date();

      // add a dynamic number of days
      maxDate.setDate(maxDate.getDate() + days);

      // set time
      maxDate.setHours(hours, minutes, seconds, mseconds);

      return new Date(formatBrowserCompatibleDate(value)) <= maxDate;
    };

    // redux state
    const eventDate = useAppSelector(
      (state) => state.formsReducer.forms?.step1?.date
    );
    const items = useAppSelector(selectSelectedItems);

    // constants
    const oldestIssuedItem = useMemo(
      () =>
        items
          .filter((i) => i.status === 'issued')
          .reduce(
            (previous, current) =>
              new Date(previous.issued) < new Date(current.issued)
                ? previous
                : current,
            { issued: '' }
          ),
      [items]
    );
    const oldestIssuedItemDateTime = DateTime.fromJSDate(
      new Date(oldestIssuedItem?.issued)
    ).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    if (name === 'date') {
      baseDateSchema = baseDateSchema.test(
        'lte',
        'This date is too far in the past.',
        (value) => {
          if (!value) return true;

          return (
            new Date(formatBrowserCompatibleDate(value)) >=
            oldestIssuedItemDateTime.toJSDate()
          );
        }
      );
    }

    if (name === 'incidentDate') {
      baseDateSchema = baseDateSchema.test(
        'lte',
        'This date is too far in the past.',
        (value) => {
          if (!value) return true;

          return (
            new Date(formatBrowserCompatibleDate(value)) >=
            oldestIssuedItemDateTime.toJSDate()
          );
        }
      );

      if (eventDate) {
        const eventDateTime = DateTime.fromJSDate(
          new Date(formatBrowserCompatibleDate(eventDate))
        ).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        baseDateSchema = baseDateSchema.test(
          'gt',
          'This date is too far in the future.',
          (value) => {
            if (!value) return true;

            return (
              new Date(formatBrowserCompatibleDate(value)) <=
              eventDateTime.toJSDate()
            );
          }
        );
      }
    }

    if (name === 'attendance') {
      baseDateSchema = baseDateSchema.test(
        'gt',
        'This date is too far in the future.',
        (value) =>
          validateMaxDate(value, {
            days: 1,
            hours: 23,
            minutes: 59,
            seconds: 0,
            mseconds: 0,
          })
      );

      const obj = {
        attendance_arrival_date: baseDateSchema.when(
          'attendance_decision',
          (attendance_decision, schema) =>
            attendance_decision
              ? schema.required('The arrival date field is required.')
              : schema
        ),
        attendance_departure_date: baseDateSchema
          .when('attendance_arrival_date', (attendance_arrival_date, schema) =>
            attendance_arrival_date
              ? schema.test(
                  'gte',
                  'The departure date field must be greater than the arrival date field.',
                  (attendance_departure_date) => {
                    if (!attendance_departure_date) return true;

                    return (
                      new Date(
                        formatBrowserCompatibleDate(attendance_departure_date)
                      ) >=
                      new Date(
                        formatBrowserCompatibleDate(attendance_arrival_date)
                      )
                    );
                  }
                )
              : schema
          )
          .when('attendance_decision', (attendance_decision, schema) =>
            attendance_decision
              ? schema.required('The departure date field is required.')
              : schema
          ),
      };

      return obj;
    }

    if (!required) return baseDateSchema;

    return baseDateSchema.required(
      `The ${replaceCamelCase(name)} field is required.`
    );
  },
  AddressPanel: (name, required) => {
    const obj: any = {};
    obj[`${name}_address_1`] = required
      ? address.required('The address-line1 field is required.')
      : address;
    obj[`${name}_address_2`] = yup.string();
    obj[`${name}_country`] = required
      ? country.required('The country field is required.')
      : country;
    obj[`${name}_city`] = required
      ? city.required('The city field is required.')
      : city;
    obj[`${name}_state`] = required
      ? state.required('The region field is required.')
      : state;
    obj[`${name}_zip_code`] = required
      ? postalCode.required('The postal code field is required.')
      : postalCode;
    obj[`${name}_phone`] = required
      ? yup.string().required('The phone field is required.')
      : yup.string();
    return obj;
  },
  UpdateBillingAddressPanel: (name, required) => {
    const obj: any = {};
    obj[`${name}_address_1`] = required
      ? address.required('The address-line1 field is required.')
      : address;
    obj[`${name}_address_2`] = yup.string();
    obj[`${name}_country`] = required
      ? country.required('The country field is required.')
      : country;
    obj[`${name}_city`] = required
      ? city.required('The city field is required.')
      : city;
    obj[`${name}_first_name`] = required
      ? customerName.required('The first name field is required.')
      : customerName;
    obj[`${name}_last_name`] = required
      ? customerName.required('The last name field is required.')
      : customerName;
    obj[`${name}_state`] = required
      ? state.required('The region field is required.')
      : state;
    obj[`${name}_zip_code`] = required
      ? postalCode.required('The postal code field is required.')
      : postalCode;
    return obj;
  },
  FileUploadPanel: (_, required) =>
    required
      ? yup
          .mixed()
          .test('required', `The upload field is required.`, (fileArray) =>
            Boolean(fileArray.length)
          )
      : yup.mixed(),
  PerilsPanel: (_, required) =>
    required
      ? yup
          .mixed()
          .test('required', `This selection is required.`, (value: Peril) =>
            Boolean(value?.name)
          )
      : yup.mixed(),
  MultiSelect: (name, required) => {
    const obj: any = {};
    obj[name] = required
      ? yup
          .mixed()
          .test('required', `This selection is required.`, (value) =>
            Boolean(value)
          )
      : yup.mixed();
    obj[`${name}_description`] = yup.string().when(name, {
      is: 'other',
      then: yup.string().required('Description is required.'),
    });
    return obj;
  },
  CreditCardPanel: (name) => {
    const obj: any = {};

    obj[`${name}_country`] = country.required('Country is required.');
    obj[`${name}_address`] = address.required('Address is required.');
    obj[`${name}_city`] = city.required('City/Town is required.');
    obj[`${name}_state`] = state.required('Province/Region is required.');
    obj[`${name}_zip_code`] = postalCode.required('Postal code is required.');
    obj[`${name}_first_name`] = customerName
      .required('First name is required.')
      .test(`valid-name`, 'Name is not valid', (value) => {
        const nameValidation = valid.cardholderName(value);
        return nameValidation.isValid;
      });
    obj[`${name}_last_name`] = customerName
      .required('Last name is required.')
      .test(`valid-name`, 'Name is not valid', (value) => {
        const nameValidation = valid.cardholderName(value);
        return nameValidation.isValid;
      });
    obj[`${name}_card_number`] = yup
      .string()
      .required('Card number is required')
      .test(`test-card-type`, 'Card number is not valid', (value) => {
        const numberValidation = valid.number(value);
        return numberValidation.isValid;
      });
    obj[`${name}_month`] = yup
      .string()
      .required('Month is required')
      .test(
        `valid-month`,
        'Month is not valid',
        (value, { createError, parent }) => {
          const currentMonth = new Date().getMonth() + 1; // January is considered 0 so adding one to align numeration
          const currentYear = new Date().getFullYear().toString().substr(-2);
          const expireYear = parent[`${name}_year`];
          if (
            parseInt(value!, 10) < currentMonth &&
            expireYear === currentYear
          ) {
            return createError({
              message:
                'Month must be greater than or equal to the current month.',
            });
          }

          const monthValidation = valid.expirationMonth(value);
          return monthValidation.isValid;
        }
      );
    obj[`${name}_year`] = yup
      .string()
      .required('Year is required')
      .test(`valid-year`, 'Year is not valid', (value) => {
        const yearValidation = valid.expirationYear(value);
        return yearValidation.isValid;
      });
    obj[`${name}_cvc`] = yup
      .string()
      .required('CVC number is required')
      .test(`valid-cvv`, 'CVC is not valid', (value, ctx) => {
        const numberValidation = valid.number(
          ctx.parent[`${name}_card_number`]
        );
        const cvvValidation = valid.cvv(
          value,
          numberValidation.card?.code.size
        );
        return cvvValidation.isValid;
      });
    return obj;
  },
};

const createValidationObject = (config: ComponentStructure) => {
  const emptyValidation = {
    validation: undefined,
    defaultValues: undefined,
  };

  // only build validation object if component supports validation and name is set in props config.json
  if (Object.keys(FormComponentEnum).includes(config.component)) {
    const props = config.props as FormFieldConfigProps;
    const component = config.component as FormComponent;

    if (!props.name) {
      // eslint-disable-next-line no-console
      console.error('Field name for form component missing', component);
      return emptyValidation;
    }

    const objectDefaults = () =>
      component === 'AddressPanel' ||
      component === 'CreditCardPanel' ||
      component === 'UpdateBillingAddressPanel' ||
      (component === 'DatePanel' &&
        (config.props as DatePanelConfigProps).name === 'attendance')
        ? resolveDefaultsForPanel(
            resolveObjectFromConfigToReduxState(props.defaultValue) || {},
            props.name
          )
        : {
            [props.name]:
              resolveObjectFromConfigToReduxState(props.defaultValue) || {},
          };

    return {
      validation:
        component === 'AddressPanel' ||
        component === 'MultiSelect' ||
        component === 'CreditCardPanel' ||
        component === 'UpdateBillingAddressPanel' ||
        (component === 'DatePanel' &&
          (config.props as DatePanelConfigProps).name === 'attendance')
          ? (validatorStrings[component](
              props.name,
              resolveToBoolean(props.required)
            ) as object)
          : {
              [props.name]: validatorStrings[component](
                props.name,
                resolveToBoolean(props.required)
              ),
            },

      /** *
       * dates need to have a defaultValue of null to work with yup validation and material ui
       * all the other fields are controlled fields so they require a value as a defaultValue
       */
      defaultValues: isObject(props.defaultValue)
        ? objectDefaults()
        : {
            [props.name]:
              resolvePropFromConfigToReduxState(props.defaultValue as string) ||
              (component === 'DatePanel' ? null : ''),
          },
    };
  }
  return emptyValidation;
};

const resolveToBoolean = (required: boolean | string | undefined): boolean =>
  Boolean(
    (required && typeof required === 'string' && required === 'true') ||
      (typeof required === 'boolean' && required)
  );

const resolveDefaultsForPanel = (defaultValue: any, name: string) => {
  const defaults: any = {};
  for (const key in defaultValue) {
    if (Object.prototype.hasOwnProperty.call(defaultValue, key)) {
      defaults[`${name}_${key}`] =
        name === 'attendance' ? null : defaultValue[key];
    }
  }
  return defaults;
};

export const buildYupValidation = (
  schema: {
    validation: { [key: string]: any };
    defaultValues: { [key: string]: string | null };
  },
  config: ComponentStructure
): {
  validation: { [key: string]: any };
  defaultValues: { [key: string]: string | null };
} => {
  // build validation recursively to accommodate for nested fields
  const props = config.props as DecisionPanelConfigProps;
  const { validation, defaultValues } = props?.childrenComponents
    ? props.childrenComponents.reduce(buildYupValidation, {
        validation: {
          [props.name]: yup.boolean(),
        },
        defaultValues: {
          [props.name]: null,
        },
      })
    : createValidationObject(config);

  return {
    validation: {
      ...schema.validation,
      ...(validation && validation),
    },
    defaultValues: {
      ...schema.defaultValues,
      ...(defaultValues && defaultValues),
    },
  };
};
