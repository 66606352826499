export const generateTransactionNumber = () => {
  // Generate a timestamp component (7 digits)
  const timestamp = Date.now() % 10 ** 7; // Ensure 7-digit length

  // Generate a random component (3 digits)
  const randomInt = Math.floor(100 + Math.random() * 900); // Random number between 100 and 999

  // Concatenate the timestamp and random components
  return `${timestamp}${randomInt}`;
};
