// react
import React, { useEffect, useState } from 'react';

// packages
import {
  Controller,
  Control,
  UseFormWatch,
  FieldValues,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

// redux
import { selectSelectedCustomer } from 'store/modules/customer/selectors';

// hooks
import { useAppSelector } from 'store/hooks';

// components
import CreditCard from 'components/CreditCard';

// utils
import {
  getChildSubdivisionMatchingCountryAndState,
  getCountriesList,
  getCountrySubdivisions,
  SubdivisionWithChildren,
} from 'utils/location';
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// types
import type { AddressFormType } from 'utils/translation/types';
import type { AutocompleteProps } from 'components/FormBuilder/components/AddressPanel/types';

type CustomerAddress = {
  address_1: string;
  country: string;
  state: string;
  city: string;
  postal_code: string;
};

type CreditCardProps = {
  control: Control;
  name: string;
  watch: UseFormWatch<FieldValues>;
  resetField: UseFormResetField<any>;
  selectedOrderAddress: CustomerAddress | undefined;
  setValue: UseFormSetValue<any>;
};

const CreditCardForm: React.VFC<CreditCardProps> = function ({
  control,
  name,
  watch,
  resetField,
  selectedOrderAddress,
  setValue,
}) {
  const translation = generateTranslation('addressForm') as AddressFormType;

  // redux
  const customer = useAppSelector(selectSelectedCustomer);

  // state
  const [countriesList, setCountriesList] = useState<AutocompleteProps[]>([]);
  const [focusedElement, setFocusedElement] = useState('');
  const [labels, setLabels] = useState<{
    address: string;
    city: string;
    state: string;
    zip_code: string;
  }>({
    address: translation.labels.address,
    city: translation.labels.city,
    state: translation.labels.state,
    zip_code: translation.labels.zip_code,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [statesFromCountry, setStatesFromCountry] = useState<
    SubdivisionWithChildren[] | ['n/a']
  >([]);

  // constants
  const addressFieldName = `${name}_address`;
  const cardHolderFirstName = `${name}_first_name`;
  const cardHolderLastName = `${name}_last_name`;
  const cardNumber = `${name}_card_number`;
  const cityFieldName = `${name}_city`;
  const countryFieldName = `${name}_country`;
  const cvc = `${name}_cvc`;
  const month = `${name}_month`;
  const stateFieldName = `${name}_state`;
  const year = `${name}_year`;
  const zip_codeFieldName = `${name}_zip_code`;

  // watch
  const cardData = watch();
  const country = watch(countryFieldName as any, selectedOrderAddress?.country);
  const state = watch(stateFieldName as any, selectedOrderAddress?.state);

  const handleFocusedElement = ({ target }) => {
    setFocusedElement(target.name);
  };

  const handleNumberOnChange = (
    value: string,
    onChange: any,
    maxChar: number
  ) => {
    const truncatedValue = value.slice(0, maxChar);

    if (!value) return onChange('');

    if (isNaN(Number(truncatedValue))) return onChange(value.slice(0, -1));

    return onChange(truncatedValue);
  };

  useEffect(() => {
    const countriesList = getCountriesList().map((country) => ({
      value: country.alpha2,
      label: country.name,
    }));

    setCountriesList(countriesList);
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === countryFieldName && type === 'change') {
        resetField(stateFieldName, {
          defaultValue: '',
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, countryFieldName, stateFieldName]);

  useEffect(() => {
    if (!country) return;

    setLabels({
      address: translation.labels.address,
      city: translation.labels.city,
      state: translation.labels.state,
      zip_code: translation.labels.zip_code,
    });

    const states = getChildSubdivisionMatchingCountryAndState(country, state);
    setStatesFromCountry(states);

    if (states && states.length && states[0] === 'n/a') {
      setValue(stateFieldName, 'n/a');
    }
  }, [
    country,
    state,
    stateFieldName,
    translation.labels.address,
    translation.labels.city,
    translation.labels.state,
    translation.labels.zip_code,
    resetField,
    setValue,
  ]);

  return (
    <Stack direction="column" justifyContent="center" spacing={2}>
      <CreditCard
        cvc={`${cardData[`${name}_cvc`]}`}
        expiry={`${cardData[`${name}_month`]}/${cardData[`${name}_year`]}`}
        name={
          `${
            cardData[`${name}_first_name`] ? cardData[`${name}_first_name`] : ''
          }${
            cardData[`${name}_last_name`]
              ? ` ${cardData[`${name}_last_name`]}`
              : ''
          }` || ''
        }
        number={cardData[`${name}_card_number`]}
        focused={focusedElement}
      />
      <Grid container item spacing={2}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography align="left" mb={2}>
              <b>{translation.ccBillingTitle}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography mb={2}>{translation.ccBillingDescription}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={countryFieldName as any}
              control={control}
              defaultValue={selectedOrderAddress?.country ?? ''}
              render={({
                field: { value, onChange, ref, ...rest },
                fieldState: { error },
              }) => (
                <>
                  {countriesList.length && (
                    <FormControl variant="filled">
                      <InputLabel id="Select-Country">
                        {translation.menuItem}
                      </InputLabel>
                      <Select
                        variant="filled"
                        sx={{
                          textAlign: 'left',
                        }}
                        inputRef={ref}
                        {...rest}
                        error={Boolean(error)}
                        value={value}
                        onChange={onChange}
                        name={countryFieldName}
                        labelId="Select-Country"
                        id="Country"
                      >
                        <MenuItem disabled selected value="">
                          {translation.menuItem}
                        </MenuItem>
                        {countriesList.map((country) => (
                          <MenuItem
                            key={`${country.value}`}
                            value={country.value}
                          >
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormHelperText error={!!error?.message}>
                    {error?.message ?? ' '}
                  </FormHelperText>
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={addressFieldName as any}
            control={control}
            defaultValue={selectedOrderAddress?.address_1 ?? ''}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name={addressFieldName}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                label={labels.address}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={cityFieldName as any}
            control={control}
            defaultValue={selectedOrderAddress?.city ?? ''}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name={cityFieldName}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                value={value ?? ''}
                label={labels.city}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={stateFieldName as any}
            control={control}
            defaultValue={selectedOrderAddress?.state ?? ''}
            render={({
              field: { value, onChange, ref, ...rest },
              fieldState: { error },
            }) => (
              <FormControl variant="filled">
                <InputLabel id="Select-State"> {labels.state}</InputLabel>
                <Select
                  variant="filled"
                  sx={{
                    textAlign: 'left',
                  }}
                  inputRef={ref}
                  {...rest}
                  value={value ?? selectedOrderAddress?.state ?? ''}
                  name={stateFieldName}
                  error={Boolean(error)}
                  labelId="Select-State"
                  id="State"
                  open={menuOpen}
                  onClose={() => {
                    const states = getChildSubdivisionMatchingCountryAndState(
                      country,
                      value
                    );
                    // when a value doesn't map to the statesFromCountry, it won't display a value on dropdown
                    // Because of this, when a subdivision with a child is selected, it won't display the child value.
                    // Because it doesn't map to the parents. The parents are the labels within the menu item.
                    // So I set the states as the child subdivision selected. When they open the menu, I revert it back
                    // To be the complete list of parents and possible child subdivisions.
                    setStatesFromCountry(states);
                    setMenuOpen(false);
                  }}
                  onOpen={() => {
                    setMenuOpen(true);
                    const states = getCountrySubdivisions(country);
                    setStatesFromCountry(states);
                  }}
                >
                  <MenuItem disabled selected value="">
                    {labels.state}
                  </MenuItem>
                  {statesFromCountry && statesFromCountry[0] === 'n/a' ? (
                    <MenuItem
                      key="region-not-applicable"
                      value="n/a"
                      onClick={() => {
                        onChange({
                          target: {
                            name: stateFieldName,
                            value: 'n/a',
                          },
                        });
                        setMenuOpen(false);
                      }}
                    >
                      n/a
                    </MenuItem>
                  ) : (
                    (statesFromCountry as SubdivisionWithChildren[]).map(
                      (state) =>
                        state.children ? (
                          <div key={`${state.code}`}>
                            <ListSubheader>{state.name}</ListSubheader>
                            {state.children.map(
                              (child: SubdivisionWithChildren) => (
                                <div key={`${child.code}`}>
                                  <MenuItem
                                    value={child.code.split('-')[1]}
                                    onClick={() => {
                                      onChange({
                                        target: {
                                          name: stateFieldName,
                                          value: child.code.split('-')[1],
                                        },
                                      });
                                      setStatesFromCountry(
                                        state.children as any
                                      );
                                      setMenuOpen(false);
                                    }}
                                  >
                                    {child.name}
                                  </MenuItem>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <MenuItem
                            key={`${state.code}`}
                            value={state.code.split('-')[1]}
                            onClick={() => {
                              onChange({
                                target: {
                                  name: stateFieldName,
                                  value: state.code.split('-')[1],
                                },
                              });
                              setMenuOpen(false);
                            }}
                          >
                            {state.name}
                          </MenuItem>
                        )
                    )
                  )}
                </Select>
                <FormHelperText error={Boolean(error?.message)}>
                  {error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={zip_codeFieldName as any}
            control={control}
            defaultValue={selectedOrderAddress?.postal_code ?? ''}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name={zip_codeFieldName}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                label={labels.zip_code}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Controller
            defaultValue={customer?.first_name ?? ''}
            name={cardHolderFirstName as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name="first-name"
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                onFocus={handleFocusedElement}
                value={value}
                label="First Name"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            defaultValue={customer?.last_name ?? ''}
            name={cardHolderLastName as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name="last-name"
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                onFocus={handleFocusedElement}
                value={value}
                label="Last Name"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name={cardNumber as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputProps={{ inputMode: 'numeric' }}
                inputRef={ref}
                {...rest}
                name="number"
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={({ target: { value } }) =>
                  handleNumberOnChange(value, onChange, 19)
                }
                onFocus={handleFocusedElement}
                value={value}
                label="Card Number"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name={month as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name="expiry-month"
                inputProps={{ inputMode: 'numeric' }}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={({ target: { value } }) =>
                  handleNumberOnChange(value, onChange, 2)
                }
                onFocus={handleFocusedElement}
                value={value}
                label="Expiry Month"
                placeholder="03"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name={year as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                inputProps={{ inputMode: 'numeric' }}
                {...rest}
                name="expiry-year"
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={({ target: { value } }) =>
                  handleNumberOnChange(value, onChange, 2)
                }
                onFocus={handleFocusedElement}
                value={value}
                label="Expiry Year"
                placeholder="27"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name={cvc as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputProps={{ inputMode: 'numeric' }}
                inputRef={ref}
                {...rest}
                name="cvc"
                onFocus={handleFocusedElement}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={({ target: { value } }) =>
                  handleNumberOnChange(value, onChange, 4)
                }
                value={value}
                label="CVC"
                placeholder="123"
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CreditCardForm;
