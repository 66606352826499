// mocks
import { fanshieldGeneralConfig } from 'mocks/products/fanshield';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

/* eslint-disable no-template-curly-in-string */

export const fanshieldBillingSubmissionMapping = {
  billing_address: {
    city: '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_city}',
    state: '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_state}',
    country: '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_country}',
    postal_code:
      '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_zip_code}',
    address_2:
      '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_address_2}',
    address_1:
      '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_address_1}',
    first_name:
      '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_first_name}',
    last_name:
      '${GLOBAL.formsReducer.forms.step1.updateBillingAddress_last_name}',
  },
};

export const fanshieldBillingSteps: ConfigStep[] = [
  {
    name: 'Billing Info',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Update Billing Address',
          footer: '',
          description:
            'In order to complete your purchase please update your billing address information.',
        },
      },
      {
        component: 'UpdateBillingAddressPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 6,
            md: 3,
          },
          name: 'updateBillingAddress',
          required: true,
          defaultValue: {
            address_1: '${GLOBAL.customerReducer.customer.address_1}',
            address_2: '${GLOBAL.customerReducer.customer.address_2}',
            first_name: '${GLOBAL.customerReducer.customer.first_name}',
            last_name: '${GLOBAL.customerReducer.customer.last_name}',
            city: '${GLOBAL.customerReducer.customer.city}',
            state: '${GLOBAL.customerReducer.customer.state}',
            zip_code: '${GLOBAL.customerReducer.customer.zip_code}',
            country: '${GLOBAL.customerReducer.customer.country}',
          },
        },
        verbiages: {
          description: '${GLOBAL.ordersReducer.selectedOrder.event.name}',
          itemCost:
            '${GLOBAL.ordersReducer.selectedOrder.order_subtotal_literal}',
          protectionCost:
            '${GLOBAL.ordersReducer.selectedOrder.order_premium_total_literal}',
          title: 'Billing Address',
          footer:
            'Please complete the required information below and click "Update Address".',
          reviewPageTitle: 'Billing Address',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Update Address',
          allCompleteText: 'Update Address',
        },
        props: {
          endpoint:
            '${GLOBAL.configFileReducer.claimsApi}/orders/${GLOBAL.ordersReducer.selectedOrder.uuid}',
          requestType: 'PATCH',
          submissionMapping: fanshieldBillingSubmissionMapping,
        },
      },
    ],
  },
  {
    name: 'Update Payment Details',
    backButtonText: '',
    conditional: '${GLOBAL.formsReducer.forms.step1.response.requires_payment}',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: "Let's Get You Protected",
          footer: '',
          description:
            "We were unable to complete your transaction to protect your purchase on the selected order. Let's retry again. Please re-enter your billing information below.",
        },
      },
      {
        component: 'CreditCardPanel',
        verbiages: {
          description: 'Please complete all fields below.',
          reviewPageTitle: 'Billing Information',
        },
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 12,
            md: 12,
          },
          name: 'creditCard',
          defaultValue: {
            card_number: '',
            first_name: '',
            last_name: '',
            month: '',
            year: '',
            cvc: '',
          },
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
        props: {
          endpoint:
            '${GLOBAL.configFileReducer.claimsApi}/orders/${GLOBAL.ordersReducer.selectedOrder.uuid}/recharge',
          requestType: 'POST',
          submissionMapping: {
            cardholder: {
              address_1:
                '${GLOBAL.formsReducer.forms.step1.creditCard_address}',
              city: '${GLOBAL.formsReducer.forms.step1.creditCard_city}',
              country: '${GLOBAL.formsReducer.forms.step1.creditCard_country}',
              first_name:
                '${GLOBAL.formsReducer.forms.step1.creditCard_first_name}',
              last_name:
                '${GLOBAL.formsReducer.forms.step1.creditCard_last_name}',
              postal_code:
                '${GLOBAL.formsReducer.forms.step1.creditCard_zip_code}',
              state: '${GLOBAL.formsReducer.forms.step1.creditCard_state}',
            },
            payment: {
              cvv: '${GLOBAL.formsReducer.forms.step2.creditCard_cvc}',
              expire_month:
                '${GLOBAL.formsReducer.forms.step2.creditCard_month}',
              expire_year: '${GLOBAL.formsReducer.forms.step2.creditCard_year}',
              name: '${GLOBAL.formsReducer.forms.step2.creditCard_first_name}',
              number:
                '${GLOBAL.formsReducer.forms.step2.creditCard_card_number}',
              type: 'card',
            },
          },
        },
      },
    ],
  },
  {
    name: 'Thank You',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const fanshieldBillingConfig: ConfigFileTypes = {
  ...fanshieldGeneralConfig,
  steps: fanshieldBillingSteps,
};

/* eslint-enable no-template-curly-in-string */
