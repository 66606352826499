// packages
import i18next from 'i18next';

// redux
import { resolvePropFromConfigToReduxState } from 'store/hooks';

import type {
  FileName,
  FilePageType,
  EmailSentType,
  OrdersTypes,
  ItemTableType,
  ItemTableWrapperType,
  ThanksPageType,
  AddressFormType,
  ReviewType,
  ReviewTableType,
  AppType,
  ContactInfoComponentType,
  PerilsPage,
  CertOfCoverageType,
  StatusColumnType,
} from 'utils/translation/types';

export function generateTranslation(file: FileName) {
  switch (file) {
    case 'filePage':
      return {
        title: translate('product.filePage.title'),
        description:
          translate('product.filePage.description') ||
          translate('general.filePage.description'),
        contactInfo: {
          linkText: translate('general.filePage.contactInfo.linkText'),
          title: translate('general.filePage.contactInfo.title'),
          introVerbiage:
            translate('product.filePage.contactInfo.introVerbiage') ||
            translate('general.filePage.contactInfo.introVerbiage'),
        },
        fileClaim: {
          buttonName: translate('general.filePage.fileClaim.buttonName'),
          placeholder: translate('general.filePage.fileClaim.placeholder'),
        },
      } as FilePageType;
    case 'emailSent':
      return {
        confirmation: translate('general.emailSent.confirmation'),
        header: translate('general.emailSent.header'),
        routerLink: translate('general.emailSent.routerLink'),
        wrong: translate('general.emailSent.wrong'),
        spam: translate('general.emailSent.spam'),
        button: translate('general.emailSent.button'),
        request: translate('general.emailSent.request'),
        description: translate('product.emailSent.description'),
      } as EmailSentType;
    case 'orders':
      return {
        button: translate('product.orderSelectionPage.button'),
        consentText: translate('product.orderSelectionPage.consentText'),
        noOrders: translate('product.orderSelectionPage.noOrders'),
        dateFilter: {
          title: translate('general.orderSelectionPage.dateFilter.title'),
          labels: {
            from: translate(
              'general.orderSelectionPage.dateFilter.labels.from'
            ),
            to: translate('general.orderSelectionPage.dateFilter.labels.to'),
          },
          buttons: {
            filter: translate(
              'general.orderSelectionPage.dateFilter.buttons.filter'
            ),
            cancel: translate(
              'general.orderSelectionPage.dateFilter.buttons.cancel'
            ),
          },
        },
        links: {
          newFlow: translate('general.orderSelectionPage.links.newFlow'),
          help: translate('general.orderSelectionPage.links.help'),
        },
        header: {
          title:
            translate('product.orderSelectionPage.header.title') ||
            translate('general.orderSelectionPage.header.title'),
          row1: translate('general.orderSelectionPage.header.row1'),
          row2: translate('product.orderSelectionPage.header.row2'),
        },
        rules: {
          title: translate('general.orderSelectionPage.rules.title'),
          row1: translate('product.orderSelectionPage.rules.row1'),
          row2: translate('product.orderSelectionPage.rules.row2'),
        },
        pagination: {
          title: translate('general.orderSelectionPage.pagination.title'),
        },
      } as OrdersTypes;
    case 'itemTable':
      return {
        header: translate('product.orderSelectionPage.itemTable.header'),
      } as ItemTableType;
    case 'statusColumn':
      return {
        header: translate(
          'general.orderSelectionPage.itemTable.statusColumn.header'
        ),
        status: {
          'awaiting fulfillment': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.awaiting_fulfillment.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.awaiting_fulfillment.description'
            ),
          },
          initiated: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_requested.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_requested.description'
            ),
          },
          closed: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_closed.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_closed.description'
            ),
          },
          'pending documents': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.documentation_pending.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.documentation_pending.description'
            ),
          },
          'pending document review': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.documentation_review.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.documentation_review.description'
            ),
          },
          'pending agent review': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_review.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_review.description'
            ),
          },
          approved: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_issued.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_issued.description'
            ),
          },
          'good will payout': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.description'
            ),
          },
          denied: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_denied.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.refund_request_denied.description'
            ),
          },
          rejected: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.rejected.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.rejected.description'
            ),
          },
          accepted: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.description'
            ),
          },
          'insufficient address': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.insufficient_address.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.insufficient_address.description'
            ),
          },
          canceled: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.description'
            ),
          },
          exchanged: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.exchanged.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.exchanged.description'
            ),
          },
          issued: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.description'
            ),
          },
          'on hold': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.on_hold.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.on_hold.description'
            ),
          },
          'ofac hold': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.review.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.review.description'
            ),
          },
          'void initiated': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.canceled.description'
            ),
          },
          voided: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.voided.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.voided.description'
            ),
          },
          'upgrade initiated': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.upgraded.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.upgraded.description'
            ),
          },
          upgraded: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.upgraded.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.upgraded.description'
            ),
          },
          'downgrade initiated': {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.downgraded.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.downgraded.description'
            ),
          },
          downgraded: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.downgraded.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.downgraded.description'
            ),
          },
          uploaded: {
            text: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.text'
            ),
            description: translate(
              'general.orderSelectionPage.itemTable.statusColumn.status.protected.description'
            ),
          },
        },
      } as StatusColumnType;
    case 'itemTableWrapper':
      return {
        error: translate('general.orderSelectionPage.orderTableWrapper.error'),
      } as ItemTableWrapperType;
    case 'thanksPage':
      return {
        button: translate('general.thankYouPage.button'),
        feedback: {
          button: translate('general.thankYouPage.feedback.button'),
          text: translate('general.thankYouPage.feedback.text'),
        },
        formControlLabel: translate('general.thankYouPage.formControlLabel'),
        textAreaDescription: translate(
          'general.thankYouPage.textAreaDescription'
        ),
        commentText: translate('general.thankYouPage.commentText'),
        rateText: translate('general.thankYouPage.rateText'),
        header: {
          title: {
            submitted: translate('product.thankYouPage.header.title.submitted'),
            notSubmitted: translate(
              'product.thankYouPage.header.title.notSubmitted'
            ),
          },
          description: {
            row1: translate('general.thankYouPage.header.description.row1'),
            row2: translate('product.thankYouPage.header.description.row2'),
            row3: translate('general.thankYouPage.header.description.row3'),
          },
        },
        description: translate('product.thankYouPage.description'),
        link:
          translate('product.thankYouPage.link') ||
          translate('general.thankYouPage.link'),
        footer:
          translate('product.thankYouPage.footer') ||
          translate('general.thankYouPage.footer'),
        links: {
          helpEmail: translate('product.links.helpEmail'),
        },
      } as ThanksPageType;
    case 'addressForm':
      return {
        labels: {
          address: translate('product.addressForm.labels.address'),
          city: translate('product.addressForm.labels.city'),
          state: translate('product.addressForm.labels.state'),
          zip_code: translate('product.addressForm.labels.zip_code'),
        },
        address: {
          placeholder1: translate('general.addressForm.address.placeholder1'),
          placeholder2: translate('general.addressForm.address.placeholder2'),
          placeholder: translate('general.addressForm.address.placeholder'),
        },
        phone: translate('general.addressForm.phone'),
        menuItem: translate('general.addressForm.menuItem'),
        ccBillingTitle: translate('general.addressForm.ccBillingTitle'),
        ccBillingDescription: translate(
          'general.addressForm.ccBillingDescription'
        ),
      } as AddressFormType;
    case 'review':
      return {
        header: {
          title: translate('general.reviewPage.header.title'),
          description: translate('product.reviewPage.header.description'),
        },
        sign: {
          title: translate('general.reviewPage.sign.title'),
          claims: {
            text: translate('product.reviewPage.sign.claims.text'),
            floridaText: translate(
              'product.reviewPage.sign.claims.floridaText'
            ),
            footer: translate('product.reviewPage.sign.claims.footer'),
            link: {
              title: translate('general.reviewPage.sign.claims.link.title'),
              text: {
                row1: translate(
                  'general.reviewPage.sign.claims.link.text.row1'
                ),
                row2: translate(
                  'product.reviewPage.sign.claims.link.text.row2'
                ),
              },
            },
          },
          button: translate('product.reviewPage.sign.button'),
          cancel: translate('product.reviewPage.sign.cancel'),
          note: translate('product.reviewPage.sign.note'),
        },
      } as ReviewType;
    case 'reviewTable':
      return {
        title: translate('product.reviewPage.reviewTable.title'),
        covered: translate('product.reviewPage.reviewTable.covered'),
        total: translate('product.reviewPage.reviewTable.total'),
      } as ReviewTableType;
    case 'app':
      return {
        title: translate('product.appComponent.title'),
        appTitle: translate('product.appComponent.appTitle'),
        footer: {
          modal: {
            title: translate('general.appComponent.footer.modal.title'),
            declineText: translate(
              'general.appComponent.footer.modal.declineText'
            ),
            submitText: translate(
              'general.appComponent.footer.modal.submitText'
            ),
            text: translate('general.appComponent.footer.modal.text'),
          },
          title: translate('product.appComponent.footer.title'),
          text: translate('general.appComponent.footer.text'),
          button: translate('general.appComponent.footer.button'),
          privacyLink: translate('general.appComponent.footer.privacyLink'),
          contactLink: translate('general.appComponent.footer.contactLink'),
          disclaimer:
            translate('general.appComponent.footer.disclaimer') === ''
              ? translate('underwriter.appComponent.footer.disclaimer')
              : translate('product.appComponent.footer.disclaimer'),
        },
      } as AppType;
    case 'contactInfo':
      return {
        text: translate('general.contactInfo.text'),
        button: translate('general.contactInfo.button'),
        description:
          translate('product.filePage.contactInfo.description') ||
          translate('general.contactInfo.description'),
        links: {
          helpEmail: translate('product.links.helpEmail'),
        },
      } as ContactInfoComponentType;
    case 'perilsPage':
      return {
        title: translate('general.perilsPage.title'),
      } as PerilsPage;
    case 'certificateOfCoveragePage':
      return {
        description: {
          part1: translate(
            'general.certificateOfCoveragePage.description.part1'
          ),
          part2: translate(
            'product.certificateOfCoveragePage.description.part2'
          ),
          product: translate(
            'product.certificateOfCoveragePage.description.product'
          ),
        },
        block1: {
          cardTitle: translate(
            'product.certificateOfCoveragePage.block1.cardTitle'
          ),
          row1: translate('product.certificateOfCoveragePage.block1.row1'),
          row2: translate('product.certificateOfCoveragePage.block1.row2'),
          title: translate('product.certificateOfCoveragePage.block1.title'),
        },
        links: {
          helpEmail: translate('product.links.helpEmail'),
        },
      } as CertOfCoverageType;
    default:
      throw new Error('Translation file type not implemented yet');
  }
}

const translate = (key: string) => {
  const resolvedTranslation = resolvePropFromConfigToReduxState(i18next.t(key));

  return resolvedTranslation === key ? '' : resolvedTranslation;
};

export const interpolate = (key: string, object: any) =>
  i18next.t(key, object) as string;
