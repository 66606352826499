// react
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

// packages
import Box from '@mui/material/Box';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';

// redux
import { selectUrlProduct } from 'store/modules/config/selectors';
import { selectSelectedOrder } from 'store/modules/orders/selectors';
import { setSelectedItemsAndOrder } from 'store/modules/orders/slice';
import { selectSelectedCustomer } from 'store/modules/customer/selectors';
import { setCustomer } from 'store/modules/customer/slice';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useBuilderForm, useRouteQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
import { getProductValue } from 'store/utils/hooks';

// components
import { CreditCardPanel } from 'components/FormBuilder/components/CreditCardPanel';
import Loader from 'components/Loader';
import LoadingButton from 'components/LoadingButton';

// utils
import { getToken } from 'utils/localstorage';
import { buildYupValidation } from 'utils/validation';
import { fanshieldRechargeConfig } from 'mocks/recharge/fanshield';
import { isEmptyObject } from 'utils/object';
import { generateTransactionNumber } from 'utils/payment';

// types
import type { FormattedOrder, ProductOrder } from 'store/modules/orders/types';
import { ProductEnum } from 'types';

const Payment = function () {
  // redux
  const customer = useAppSelector(selectSelectedCustomer);
  const order = useAppSelector(selectSelectedOrder);
  const product = getProductValue(useAppSelector(selectUrlProduct));

  // constants
  const isRaas =
    product === ProductEnum.enhancedrefundprogram ||
    product === ProductEnum.optionalRAAS;
  const token = getToken();
  const authHeaders = useCallback(
    () => ({
      authorization: `Bearer ${token}`,
    }),
    [token]
  );
  const { validation, defaultValues } =
    fanshieldRechargeConfig.steps[0].fields.reduce(buildYupValidation, {
      validation: {},
      defaultValues: {},
    });

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formApi = useBuilderForm(`payment`, {
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(object(validation)),
  });
  const { order_id } = useRouteQueryParams();

  // state
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [hasFetchedOrder, setHasFetchedOrder] = useState(false);
  const [isFetchingTAC, setIsFetchingTAC] = useState(false);
  const [TAC, setTAC] = useState('');
  const [uuid, setUUID] = useState();
  const [transactionNumber, setTransactionNumber] = useState('');

  // constants
  const isSubmitting = isFetchingTAC;

  // functions
  const formatOrder = (order?: ProductOrder): FormattedOrder => {
    if (!order || isEmptyObject(order))
      return {
        client_display_name: '',
        client_id: '',
        client_name: '',
        created: '',
        currency: '',
        customer: {
          address_1: '',
          city: '',
          country: '',
          phone: '',
          postal_code: '',
          state: '',
        },
        event: undefined,
        items: [],
        jurisdiction: {
          country: '',
          region: '',
        },
        locale: 'en_US',
        order_premium_total: '',
        order_premium_total_literal: '',
        order_subtotal: '',
        order_subtotal_literal: '',
        reference_id: '',
        uuid: '',
      };

    return {
      client_display_name: order.client.display_name,
      client_id: order.client.id,
      client_name: order.client.name,
      created: order.created,
      currency: order.currency,
      customer: {
        address_1: '',
        city: '',
        country: '',
        phone: order.customer.phone,
        postal_code: '',
        state: '',
      },
      event: undefined,
      items: [],
      jurisdiction: {
        country: order.jurisdiction?.country ?? '',
        region: order.jurisdiction?.region ?? '',
      },
      locale: order.customer.locale,
      order_premium_total: order.order_premium_total,
      order_premium_total_literal: order.order_premium_total_literal,
      order_subtotal: order.order_subtotal,
      order_subtotal_literal: order.order_subtotal_literal,
      reference_id: order.reference_id,
      uuid: order.id,
    };
  };

  const formatCustomer = (order?: ProductOrder) => {
    if (!order || isEmptyObject(order))
      return {
        id: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
      };

    return {
      id: order.customer.id,
      email: order.customer.email,
      first_name: order.customer.first_name,
      last_name: order.customer.last_name,
      phone: order.customer.phone,
    };
  };

  const getCurrencyNumericalCode = (value: unknown) => {
    if (!value || typeof value !== 'string') return '';

    const currencyMapping =
      JSON.parse(process.env.REACT_APP_CURRENCY_NUMERICAL_CODE || '') || {};
    const valueToUppercase = value.toUpperCase();

    if (
      isEmptyObject(currencyMapping) ||
      !(valueToUppercase in currencyMapping)
    )
      return '';

    return currencyMapping[valueToUppercase];
  };

  const handlePaymentFailure = useCallback(() => {
    history.push('/payment-failure');
  }, [history]);

  const handleFetchOrder = useCallback(
    async (order_id: string) => {
      if (hasFetchedOrder || isFetchingOrder) return;

      setIsFetchingOrder(true);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_V4_URL}/orders/${order_id}`,
          {
            headers: { ...authHeaders() },
          }
        );

        if (!res.ok) throw new Error('Could not fetch order details.');

        const body = await res.json();

        if (
          body?.items?.every(
            (i) =>
              i.status?.toLowerCase() === 'accepted' ||
              i.status?.toLowerCase() === 'issued'
          )
        )
          return history.push('/payment-failure');

        dispatch(
          setSelectedItemsAndOrder({
            order: formatOrder(body),
            items: body?.items,
          })
        );
        dispatch(setCustomer(formatCustomer(body)));

        setHasFetchedOrder(true);

        return body;
      } catch (error) {
        handlePaymentFailure();
      } finally {
        setIsFetchingOrder(false);
        setIsLoading(false);
      }
    },
    [
      authHeaders,
      dispatch,
      handlePaymentFailure,
      history,
      hasFetchedOrder,
      isFetchingOrder,
    ]
  );

  const handleGenerateTAC = useCallback(async () => {
    if (TAC || isFetchingTAC) return;

    setIsFetchingTAC(true);

    try {
      if (!order?.uuid)
        throw new Error('Order ID is required to generate TAC.');

      if (!order?.currency)
        throw new Error('Order currency is required to generate TAC.');

      if (!order?.order_premium_total)
        throw new Error('Order premium total is required to generate TAC.');

      if (!customer?.email)
        throw new Error('Customer email is required to generate TAC.');

      const res = await fetch(
        `${process.env.REACT_APP_API_INTERNAL_URL}/epx/tac`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...authHeaders(),
          },
          body: JSON.stringify({
            amount: order.order_premium_total,
            currency_code: getCurrencyNumericalCode(order.currency),
            tran_nbr: transactionNumber ?? '',
            // order ID
            user_data_1: order?.uuid ?? '',
            // session token
            user_data_2: uuid,
          }),
        }
      );

      if (!res.ok) throw new Error('Something went wrong generating a TAC.');

      const body = await res.json();

      setTAC(body);
    } catch (error) {
      handlePaymentFailure();
    } finally {
      setIsFetchingTAC(false);
    }
  }, [
    authHeaders,
    customer.email,
    handlePaymentFailure,
    isFetchingTAC,
    order?.currency,
    order?.order_premium_total,
    order?.uuid,
    TAC,
    transactionNumber,
    uuid,
  ]);

  // if there IS an order in redux and there IS NOT an order ID query param in the URL, we need to redirect to the payment failure page
  useEffect(() => {
    if (order || order_id || hasFetchedOrder) return;

    handlePaymentFailure();
  }, [handlePaymentFailure, hasFetchedOrder, order, order_id]);

  useLayoutEffect(() => {
    if (uuid) return;

    setUUID(uuidv4);
  }, [uuid]);

  useLayoutEffect(() => {
    if (transactionNumber) return;

    setTransactionNumber(generateTransactionNumber());
  }, [transactionNumber]);

  // if there IS an order ID query param in the URL, we want to fetch its details
  useLayoutEffect(() => {
    if (!order_id) return;

    handleFetchOrder(order_id);
  }, [handleFetchOrder, order_id]);

  useLayoutEffect(() => {
    if (!hasFetchedOrder) return;

    handleGenerateTAC();
  });

  // if there's not a fetch error, and we're in the process of loading or fetching, display a loading indicator
  if (isLoading || isFetchingOrder) return <Loader />;

  const form = () => ({
    account_nbr: formApi.getValues('creditCard_card_number') ?? '',
    address: formApi.getValues('creditCard_address') ?? '',
    amount: order?.order_premium_total ?? '',
    cust_nbr: process.env.REACT_APP_CUSTOMER_NUMBER ?? '',
    currency_code: getCurrencyNumericalCode(order?.currency),
    cvv2: formApi.getValues('creditCard_cvc') ?? '',
    dba_nbr: process.env.REACT_APP_DBA_NUMBER ?? '',
    exp_date: `${formApi.getValues('creditCard_year')}${formApi.getValues(
      `creditCard_month`
    )}`,
    first_name: formApi.getValues('creditCard_first_name') ?? '',
    industry_type: 'E',
    last_name: formApi.getValues('creditCard_last_name') ?? '',
    merch_nbr: isRaas
      ? process.env.REACT_APP_MERCHANT_NUMBER_RAAS
      : process.env.REACT_APP_MERCHANT_NUMBER_TI ?? '',
    redirect_echo: 'V',
    response_echo: 'V',
    tac: TAC,
    terminal_nbr: process.env.REACT_APP_TERMINAL_NUMBER ?? '',
    tran_code: 'SALE',
    tran_nbr: transactionNumber ?? '',
    // order ID
    user_data_1: order?.uuid ?? '',
    // session token
    user_data_2: uuid,
    zip_code: formApi.getValues('creditCard_zip_code') ?? '',
  });

  return (
    <>
      <CreditCardPanel
        description="Please complete all fields below."
        forceShowReviewTable
        formApi={formApi}
        name="creditCard"
      />
      <Box sx={{ pt: 4, textAlign: 'center' }}>
        <form
          action={
            process.env.REACT_APP_BROWSER_POST_URL ??
            // default to prod
            'https://services.epx.com/browserpost/'
          }
          method="POST"
        >
          {Object.keys(form()).map((id) => (
            <input
              id={id}
              key={id}
              name={id.toUpperCase()}
              type="hidden"
              value={form()[id]}
            />
          ))}
          <LoadingButton
            className="btn-style"
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            variant="contained"
          >
            Next
          </LoadingButton>
        </form>
      </Box>
    </>
  );
};

export default Payment;
