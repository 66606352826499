// react
import React, { useEffect } from 'react';

// packages
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// redux
import { setCustomer } from 'store/modules/customer/slice';

// hooks
import { useRouteQueryParams } from 'hooks';
import { useAppDispatch } from 'store/hooks';

// components
import HeaderDescription from 'components/HeaderDescription';
import PrintButton from 'components/Print';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// types
import type { ThanksPageType } from 'utils/translation/types';

const PaymentSuccess = function () {
  // constants
  const links = {
    helpEmail: 'mailto:help@requestmyrefund.com',
  };

  // hooks
  const dispatch = useAppDispatch();
  const { email, name } = useRouteQueryParams();

  useEffect(() => {
    dispatch(
      setCustomer({
        email: email ?? '',
        name: name ?? '',
      })
    );
  }, [dispatch, email, name]);

  // constants
  const translation = generateTranslation('thanksPage') as ThanksPageType;

  return (
    <>
      <Box className="text-right">
        <PrintButton />
      </Box>
      <Stack alignItems="center">
        <HeaderDescription title={translation.header.title.notSubmitted}>
          <Typography className="text-center" my={3}>
            {translation.description}{' '}
            <Link
              underline="always"
              href={translation?.links?.helpEmail || links.helpEmail}
              target="_blank"
            >
              {translation.link}
            </Link>
            {translation.footer}
          </Typography>
        </HeaderDescription>
      </Stack>
    </>
  );
};

export default PaymentSuccess;
